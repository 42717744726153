import { io, Socket } from 'socket.io-client';
import { logger } from '../helpers';
import { LogSeverity } from '../types';
import { refreshToken } from './auth';
import instance from '.';

let socket: Socket | null = null;
let connectionPromise: Promise<Socket> | null = null;
let reconnectTimer: NodeJS.Timeout | null = null;
let isManuallyDisconnected = false;

/**
 * Opens a WebSocket connection, handling token refresh and reconnection logic.
 */
export async function openWebsocket(): Promise<void> {
    if (socket) {
        logger({ severity: LogSeverity.WARN, message: `[WebSocket] Already open` });
        return;
    }
    if (connectionPromise) {
        logger({ severity: LogSeverity.WARN, message: `[WebSocket] Already connecting` });
        return;
    }

    const baseURL = instance.defaults.baseURL;
    const accessToken = await refreshToken(); // Obtain access token

    if (!baseURL || !accessToken) {
        logger({ severity: LogSeverity.ERROR, message: `[WebSocket] Invalid baseURL or accessToken` });
        return;
    }

    try {
        socket = io(baseURL, {
            extraHeaders: { Authorization: `Bearer ${accessToken}` },
            reconnection: true,
            reconnectionDelay: 2000, // Start with 2s delay
            reconnectionDelayMax: 15000, // Increase delay up to 15s
            reconnectionAttempts: Infinity, // Retry indefinitely
            autoConnect: false, // Avoid auto-connect issues
        });

        socket.on('connect', () => {
            logger({ severity: LogSeverity.VERBOSE, message: `[WebSocket] Connected` });
            connectionPromise = null;
            isManuallyDisconnected = false;
        });

        socket.on('disconnect', (reason) => {
            if (!isManuallyDisconnected) {
                logger({ severity: LogSeverity.WARN, message: `[WebSocket] Disconnected: ${reason}. Attempting reconnect...` });
                attemptReconnect();
            }
        });

        socket.on('connect_error', async (error) => {
            logger({ severity: LogSeverity.ERROR, message: `[WebSocket] Connection error: ${error.message}` });

            if (error.message === 'Token expired') {
                try {
                    const newToken = await refreshToken();
                    socket!.io.opts.extraHeaders.Authorization = `Bearer ${newToken}`;
                    logger({ severity: LogSeverity.VERBOSE, message: `[WebSocket] Reconnecting with new token` });
                    socket!.connect();
                } catch (tokenError) {
                    logger({ severity: LogSeverity.ERROR, message: `[WebSocket] Failed to refresh token: ${tokenError.message}` });
                }
            }
        });

        socket.connect();
    } catch (error) {
        logger({ severity: LogSeverity.ERROR, message: `[WebSocket] Failed to open: ${(error as Error).message}` });
    }
}

/**
 * Attempts to reconnect the WebSocket connection if it's lost.
 */
function attemptReconnect() {
    if (reconnectTimer) clearTimeout(reconnectTimer);

    reconnectTimer = setTimeout(() => {
        if (socket && !socket.connected) {
            logger({ severity: LogSeverity.WARN, message: `[WebSocket] Attempting to reconnect...` });
            socket.connect();
        }
    }, 5000);
}

/**
 * Closes the WebSocket connection manually.
 */
export function closeWebsocket() {
    if (socket) {
        isManuallyDisconnected = true;
        socket.close();
        socket = null;
        connectionPromise = null;
        logger({ severity: LogSeverity.WARN, message: `[WebSocket] Disconnected manually` });
    }
}

/**
 * Subscribes to a WebSocket event.
 */
export async function subscribeWebsocket(event: string, listener: (data: any) => void): Promise<void> {
    if (!socket) await openWebsocket();
    if (!socket) return;

    socket.on(event, listener);
    logger({ severity: LogSeverity.VERBOSE, message: `[WebSocket] Subscribed to: ${event}` });
}

/**
 * Unsubscribes from a WebSocket event.
 */
export function unsubscribeWebsocket(event: string, listener?: (data: any) => void): void {
    if (!socket) {
        logger({ severity: LogSeverity.ERROR, message: `[WebSocket] Not connected` });
        return;
    }

    socket.off(event, listener);
    logger({ severity: LogSeverity.VERBOSE, message: `[WebSocket] Unsubscribed from: ${event}` });
}

/**
 * Handles reconnecting WebSockets when the system wakes up from sleep.
 */
if (typeof window !== 'undefined') {
    window.addEventListener('online', () => {
        logger({ severity: LogSeverity.LOG, message: `[WebSocket] Network is back online. Reconnecting...` });
        attemptReconnect();
    });

    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
            logger({ severity: LogSeverity.LOG, message: `[WebSocket] Tab is visible. Reconnecting...` });
            attemptReconnect();
        }
    });
}